import * as Turbo from '@hotwired/turbo'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/libs/jquery/jquery.js'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/libs/popper/popper.js'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/js/bootstrap.js'

import 'sneat-bootstrap-html-admin-template-free/assets/vendor/js/helpers.js'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/js/menu.js'

import consumer from "./consumer"

import "chartkick/chart.js"

Turbo.start()
document.addEventListener("turbo:load", onLoad);
document.addEventListener("turbo:submit-end", onLoad);
let menu, animate;
function onLoad() {
  // Initialize menu
  //-----------------

  let layoutMenuEl = document.querySelectorAll('#layout-menu');
  layoutMenuEl.forEach(function (element) {
    menu = new Menu(element, {
      orientation: 'vertical',
      closeChildren: false
    });
    // Change parameter to true if you want scroll animation
    window.Helpers.scrollToActive((animate = false));
    window.Helpers.mainMenu = menu;
  });

  let fileInputs = document.querySelectorAll('input.form-control.file');
  fileInputs.forEach(input => {
    const previewImg = document.querySelector('#preview-image-'+input.id)
    if (previewImg) {
      input.onchange = evt => {
        const [file] = input.files
        if (file) {
          previewImg.src = URL.createObjectURL(file)
        }
      }
    }
  })
  

  let ordersContainer = document.querySelectorAll('.orders-container');
  ordersContainer.forEach(container => {
    consumer.subscriptions.create({ channel: "MensaChannel::OrderChannel", token: container.dataset.token }, {
      received(data) {
        if (container.querySelector(".none-placeholder")) container.querySelector(".none-placeholder").remove()
        container.innerHTML = data.html + container.innerHTML
      },
    })
  })

  // Display menu toggle (layout-menu-toggle) on hover with delay
  let delay = function (elem, callback) {
    let timeout = null;
    elem.onmouseenter = function () {
      // Set timeout to be a timer which will invoke callback after 300ms (not for small screen)
      if (!Helpers.isSmallScreen()) {
        timeout = setTimeout(callback, 300);
      } else {
        timeout = setTimeout(callback, 0);
      }
    };

    elem.onmouseleave = function () {
      // Clear any timers set to timeout
      document.querySelector('.layout-menu-toggle').classList.remove('d-block');
      clearTimeout(timeout);
    };
  };
  if (document.getElementById('layout-menu')) {
    delay(document.getElementById('layout-menu'), function () {
      // not for small screen
      if (!Helpers.isSmallScreen()) {
        document.querySelector('.layout-menu-toggle').classList.add('d-block');
      }
    });
  }

  // Display in main menu when menu scrolls
  let menuInnerContainer = document.getElementsByClassName('menu-inner'),
    menuInnerShadow = document.getElementsByClassName('menu-inner-shadow')[0];
  if (menuInnerContainer.length > 0 && menuInnerShadow) {
    menuInnerContainer[0].addEventListener('ps-scroll-y', function () {
      if (this.querySelector('.ps__thumb-y').offsetTop) {
        menuInnerShadow.style.display = 'block';
      } else {
        menuInnerShadow.style.display = 'none';
      }
    });
  }

  // Init helpers & misc
  // --------------------

  // Init BS Tooltip
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Accordion active class
  const accordionActiveFunction = function (e) {
    if (e.type == 'show.bs.collapse' || e.type == 'show.bs.collapse') {
      e.target.closest('.accordion-item').classList.add('active');
    } else {
      e.target.closest('.accordion-item').classList.remove('active');
    }
  };

  const accordionTriggerList = [].slice.call(document.querySelectorAll('.accordion'));
  const accordionList = accordionTriggerList.map(function (accordionTriggerEl) {
    accordionTriggerEl.addEventListener('show.bs.collapse', accordionActiveFunction);
    accordionTriggerEl.addEventListener('hide.bs.collapse', accordionActiveFunction);
  });

  // Auto update layout based on screen size
  window.Helpers.setAutoUpdate(true);

  // Toggle Password Visibility
  window.Helpers.initPasswordToggle();

  // Manage menu expanded/collapsed with templateCustomizer & local storage
  //------------------------------------------------------------------

  // If current layout is horizontal OR current window screen is small (overlay menu) than return from here
  // if (window.Helpers.isSmallScreen()) {
  //   return;
  // }

  // If current layout is vertical and current window screen is > small

  // Auto update menu collapsed/expanded based on the themeConfig
  window.Helpers.setCollapsed(true, false);


  let submitOnChange = document.querySelectorAll('.submit-on-change');
  submitOnChange.forEach(e => {
    e.addEventListener('change', (event) => {
      setTimeout(() => {
        e.parentElement.parentElement.submit()
      }, 500);
    })
  })

  function checkCheckSwitchs(input){
    document.querySelectorAll('.content_'+input.id).forEach(content => {
      if (input.checked){
        content.classList.remove("d-none")
      }else{
        content.classList.add("d-none")
      }
    })
    document.querySelectorAll('.content_negativ_'+input.id).forEach(content => {
      if (input.checked){
        content.classList.add("hidden")
      }else{
        content.classList.remove("hidden")
      }
    })
  }

  window.mealDayCount = 0
  let mealButtons = document.querySelectorAll('.meal-button');
  mealButtons.forEach(button => {
    button.addEventListener('click', () => {
      if (button.classList.contains("meal-next")) window.mealDayCount -= 1
      if (button.classList.contains("meal-prev")) window.mealDayCount += 1
      fetch("/api/meal/"+window.mealDayCount+"/"+button.dataset.token).then((response) => response.text()).then((html) => {
        document.querySelector('.meal-content').innerHTML = html
      })
    })
  })


  let checkSwitchs = document.querySelectorAll('.checkSwitch');
  checkSwitchs.forEach(input => {
    checkCheckSwitchs(input)
    input.addEventListener('input', () => {
      checkCheckSwitchs(input)
    })
  })

  const toggleSelectsInnerFunction = function (e) {
    document.querySelectorAll('.content_'+e.id).forEach(ee => {
      ee.classList.add("d-none")
    })
    document.querySelectorAll('.content_'+e.id+'_'+e.value).forEach(ee => {
      ee.classList.remove("d-none")
    })
  }

  let toggleSelects = document.querySelectorAll('.toggle-select');
  toggleSelects.forEach(e => {
    toggleSelectsInnerFunction(e)
    e.addEventListener('change', (event) => {
      toggleSelectsInnerFunction(e)
    })
  })

  let clearButtons = document.querySelectorAll('.clear-button');
  clearButtons.forEach(e => {
    e.addEventListener('click', (event) => {
      e.parentElement.querySelectorAll('.form-select').forEach(s => {
        s.value = "" 
      })
    })
  })

  let asyncButtons = document.querySelectorAll('.async-button');
  asyncButtons.forEach(e => {
    e.addEventListener('click', (event) => {
      event.preventDefault();

      let params

      if (document.getElementById(e.id.replace("-btn", "-input"))){
        params = JSON.stringify({input: document.getElementById(e.id.replace("-btn", "-input")).value})
      }
      
      fetch(e.dataset.url, {
        method: e.dataset.method || 'DELETE',
        body: params,
        headers: {
          "X-CSRF-Token": e.dataset.authenticityToken,
          "Content-Type": "application/json"
        }
      }).then(response => {
        if (response.status == 305){
          location.reload()
        } else if (response.redirected || response.status == 301) {
          window.location.href = response.url;
        }
      })
    })
  })
}